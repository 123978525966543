.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .proposal-header {
    margin-bottom: 40px;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .logo {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .flex-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th {
    border-bottom: 2px solid #000;
    padding: 10px;
    text-align: left;
  }
  
  .table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .subtotal-row td {
    font-weight: bold;
  }

  .values-align-right {
    text-align: right;
  }
  
  .signature-section {
    text-align: left;
    margin-top: 40px;
  }
  
  .signature-box {
    border: 1px solid #ddd;
    padding: 0;
    border-radius: 4px;
  }
  
  .footer {
    padding-top: 20px;
    font-family: Arial, sans-serif;
    page-break-before: always;
  }

  .footer ul {
    list-style-type: disc; /* Ensures bullet points are visible */
    margin-left: 20px; /* Adds space for bullets to be properly positioned */
    padding-left: 20px; /* Adds indentation for a clean look */
  }
  
  .footer li {
    margin-bottom: 10px;
    line-height: 1.5; /* Optional: Makes the list more readable */
  }
  
  .footer p {
    margin: 10px 0;
  }
  
  .bold-text {
    font-family: Poppins-Bold !important;
  }
  
  .signature-canvas {
    width: 100%;
    height: 90px;
  }
  
  /* General styles for preventing text overflow */
.proposal-header, .footer, .signature-section, .table, .flex-row {
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
    .proposal-header h3 {
        font-size: 18px;
    }

    .footer p, .footer ul li, .signature-section p {
        font-size: 12px;
    }

    .flex-row p {
        font-size: 14px;
    }

        .table th,
        .table td {
        font-size: 12px;
    }

    .signature-box canvas {
        width: 100% !important;
    }
}